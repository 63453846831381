/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, Menu, ColumnWrapper, Image, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, Title, Text } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Kontakt"}>
        <Column className="--menu pb--0 pt--0" style={{"marginTop":0,"marginBottom":0,"backgroundColor":"rgba(199,18,18,1)"}} menu={true} border={null}>
        </Column>


        <Column className="--menu pb--08 pt--08" style={{"backgroundColor":"rgba(255,255,255,1)"}} menu={true}>
          
          <Menu className="--right" style={{"maxWidth":1310}} fullscreen={false}>
            
            <ColumnWrapper className="menu-logo-box --center" style={{"maxWidth":310}}>
              
              <Image style={{"maxWidth":142}} alt={""} src={"https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=860x_.png"} svg={false} href={"/"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=350x_.png 350w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=660x_.png 660w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=860x_.png 860w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=1400x_.png 1400w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=2000x_.png 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox className="--style4 --right">
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/kurzy"} target={""} content={"Kurzy"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/zajezdy"} target={""} content={"Zájezdy"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/obchod"} target={""} content={"Obchod"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/sluzby"} target={""} content={"Služby"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/bazen"} target={""} content={"Bazén"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/klub"} target={""} content={"Klub"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/o-nas"} target={""} content={"O nás"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/fotogalerie"} target={""} content={"Fotogalerie"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/kontakt"} target={""} content={"Kontakt"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column className="--center" style={{"marginTop":0,"paddingTop":57,"marginBottom":0,"paddingBottom":23.84166717529297}} name={"wmvpcxzry3a"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--style6 title-box--center ff--3" content={"Kontakt"}>
              </Title>

              <Text className="text-box text-box--center ff--3 fs--30" style={{"marginTop":5.67498779296875,"paddingTop":0}} content={"Orca Diving, s.r.o.<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"marginTop":0,"paddingTop":46,"paddingBottom":0,"backgroundColor":"rgba(182, 202, 209, 1)"}} name={"3xlkx61lfxt"}>
          
          <ColumnWrap className="column__flex --center el--3 flex--top" style={{"maxWidth":1016,"marginTop":0,"paddingTop":0}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":287,"paddingBottom":0}}>
              
              <Text className="text-box text-box--center ff--3 fs--16" style={{"maxWidth":650,"marginBottom":0,"paddingBottom":0}} content={"<font color=\"rgba(0,0,0,1)\">Adresa:<br><br>Ledařská 433/9\n<br>147 00 Praha 4\n\n<br><br></font>"}>
              </Text>

              <Image style={{"maxWidth":51,"marginTop":0,"paddingTop":0,"paddingBottom":0}} src={"https://cdn.swbpg.com/t/13078/2ae4e80fe33141faa55ea070fa8ac71d_s=350x_.png"} svg={false} href={"https://www.facebook.com/orcadivingpotapeni"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/13078/2ae4e80fe33141faa55ea070fa8ac71d_s=350x_.png 350w"}>
              </Image>

              <Image style={{"maxWidth":64,"marginTop":0,"paddingTop":0,"paddingBottom":0}} src={"https://cdn.swbpg.com/o/13078/cd3e7fe653c049d4898165ba0b45b3cf.svg"} href={"https://l.facebook.com/l.php?u=https%3A%2F%2Fwww.instagram.com%2Forcadivingcz%3Figsh%3DMTRoZ295ZGUyZDVnNQ%253D%253D%26utm_source%3Dqr%26fbclid%3DIwZXh0bgNhZW0CMTAAAR1p22txBsciolBAHhfufTdTYvEBLm81Ihmh-FbIeD5sTWKk3mt6AuyMpX4_aem_AbhytgLILchXlGyEIDGFvL2KnLNTnUgCKPqeqK9tkQQ1YYUPQ9jDennJ5XaxDjYqIKif6QOL0PklC4bTx79__Tnw&h=AT1wWA4FoOyadIfazpChp7dvqqQadGvBB1Q1u-lj8VuYmUkvYt4uQXrdPtmUcs_k9Koi4m_5pSpVvBpTdgwsUULkX4Aq6X_yuPdbVNF64NvrygPx6uNQv_VhA5VX7MiyC8c"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={""} url={"https://l.facebook.com/l.php?u=https%3A%2F%2Fwww.instagram.com%2Forcadivingcz%3Figsh%3DMTRoZ295ZGUyZDVnNQ%253D%253D%26utm_source%3Dqr%26fbclid%3DIwZXh0bgNhZW0CMTAAAR1p22txBsciolBAHhfufTdTYvEBLm81Ihmh-FbIeD5sTWKk3mt6AuyMpX4_aem_AbhytgLILchXlGyEIDGFvL2KnLNTnUgCKPqeqK9tkQQ1YYUPQ9jDennJ5XaxDjYqIKif6QOL0PklC4bTx79__Tnw&h=AT1wWA4FoOyadIfazpChp7dvqqQadGvBB1Q1u-lj8VuYmUkvYt4uQXrdPtmUcs_k9Koi4m_5pSpVvBpTdgwsUULkX4Aq6X_yuPdbVNF64NvrygPx6uNQv_VhA5VX7MiyC8c"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box ff--3 w--500" style={{"marginBottom":0}} content={"<span style=\"color: rgb(0, 0, 0);\">Otevírací doba prodejny:</span><br><br><span style=\"color: rgb(0, 0, 0);\">Po, St, Pá 10:00 - 17:00</span><br><span style=\"color: rgb(0, 0, 0);\">Út, Čt 10:00 - 18:00</span><br><br>"}>
              </Text>

              <Image style={{"maxWidth":345,"marginTop":3}} src={"https://cdn.swbpg.com/o/13078/97293c6307b244e89fa5b39246c61ddc.PNG"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={""}>
              </Image>

              <Text className="text-box ff--3" style={{"marginTop":0}} content={"<span style=\"font-weight: bold;\">Prosíme, volejte pouze v pracovní době!</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--center ff--3 fs--16" content={"<font color=\"rgba(0,0,0,1)\">Fakturační údaje:\n<br><br>IČO: 26445344\n<br>DIČ: CZ26445344\n<br>Číslo účtu ČSOB: 300 320 313/0300\n<br>IBAN: CZ55 0300 0000 0003 0032 0313\n<br>BIC: CEKOCZPP</font>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"marginTop":0,"paddingTop":4,"paddingBottom":35.508331298828125,"backgroundColor":"rgba(182, 203, 211, 1)"}} name={"kyken4n91af"} layout={"l8"}>
          
          <ColumnWrap className="column__flex --center el--2" style={{"maxWidth":546}} columns={"2"}>
            
            <ColumnWrapper >
              
              <Image className="--shape4" src={"https://cdn.swbpg.com/t/13078/51d13c6d96f4430191d8331491791695_s=660x_.png"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13078/51d13c6d96f4430191d8331491791695_s=350x_.png 350w, https://cdn.swbpg.com/t/13078/51d13c6d96f4430191d8331491791695_s=660x_.png 660w, https://cdn.swbpg.com/t/13078/51d13c6d96f4430191d8331491791695_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape4" src={"https://cdn.swbpg.com/t/13078/a91637a9475a433e9bd44736785602d0_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13078/a91637a9475a433e9bd44736785602d0_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/a91637a9475a433e9bd44736785602d0_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/a91637a9475a433e9bd44736785602d0_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/a91637a9475a433e9bd44736785602d0_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60" style={{"marginTop":0,"paddingTop":0}} name={"lfzkbv9ksbj"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box ff--3 fs--26" content={"Jak k nám do Orca Diving<br>"}>
              </Title>

              <Text className="text-box ff--3" style={{"maxWidth":968,"marginTop":21.39166259765625,"paddingTop":0}} content={"Orku naleznete kousek od Barrandovského mostu v těsné blízkosti Jižní spojky. Příjezd je ulicí U Kempingu a Ledařská. Před domem je parkoviště, kde se dá bez problémů zaparkovat.\n\n<br><br>Podrobná cesta k Orce autem: je to na pravém břehu Vltavy, Braník, nedaleko od Barrandovského mostu od křižovatky s Jižní spojkou na jih proti proudu Vltavy po hlavní Modřanské ulici/silnici 314 m a na prvních světlech (křižovatka ulic Modřanská a Údolní) doprava (kurz 287°), po 134 m zase doprava (kurz 322°), po 322 m kolem Ledáren zase doprava (kurz 111°) a odtud je vpravo (kurz 192°) vidět ve vzdálenosti 50 m třípatrový dům s reklamou na štítě, kde je dole vchod s označením a osvětleným plakátem ORCA, parkování bez problémů, event. o 50 m dále za domem, adresa Ledařská 433/9, 147 00 Praha 4 – Braník.\n<br><br>Podrobná cesta k Orce od MHD: je to na pravém břehu Vltavy, Braník, nedaleko od Barrandovského mostu, ze stanice Výtoň tam nyní jedou všechny tramvaje (T 2, 3, 17, 21), když tram podjede Barrandovský most, tak se vystupuje ve stanici Pobřežní cesta, pozor, je to zastávka na znamení, po vystoupení kousek - 27 m - zpět dolů s tramvajového náspu (azimut 296°) a na T-křižovatce pak ostře doleva po asfaltovém chodníku stejným směrem jako tramvaj (azimut 149°), po pravé straně je skate park, a po 111 m dojdeme na T-křižovatku, odkud je vidět v podobném směru (190°) třípatrový dům s osvětleným plakátem Orca, sídlo klubu, stačí dojít 56 m, adresa Ledařská 433/9, 147 00 Praha 4 – Braník."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}